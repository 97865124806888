@import '../primitives/surfaces.scss';
@import '../config/color-palette.scss';
@import '../config/parameters.scss';
 
:root {
    .aq-switch {
        display: inline-block;
        height: 20px;
        vertical-align: middle;
 
        *[class*="slider"] {
            border: 1px solid #777;
            background: #f3f3f3;
        }
 
        &[class*="checked"] {
            *[class*="slider"] {
                background: $primary !important;
                border: 1px solid $primary !important;
            }
        }
    }
 
    .aq-switch-label {
        vertical-align: middle;
        margin-right: 12px;
        font-size: 14px;
    }
}