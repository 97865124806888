@import '../primitives/surfaces.scss';
@import '../config/color-palette.scss';
@import '../config/parameters.scss';

:root {
    .aq-input {
        border: transparent !important;
        border-radius: 0 !important;
        border-bottom: 1px solid #165788 !important;
        background: none !important;

        width: -webkit-fill-available;
        outline: 0 !important;
        box-shadow: none !important;
    }


    .aq-input:active {
        border-bottom: 1px solid #165788 !important;
        outline: 0 !important;
    }

    .aq-input:hover {
        border-bottom: 1px solid #165788 !important;
        outline: 0 !important;
    }
}