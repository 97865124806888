:root {
    .aq-slider {
        height: 16px;
        margin: 1px;
        /* height: 10px; */
        border-radius: 12px;
        box-shadow: inset 0 1px #fff7, inset 0 -1px #0002, 0 0 0 1px #0008;

        &-horizontal {
            height: 16px;
        }

        &-vertical {
            width: 16px;
            height: 100%;
        }

        *[class*="handle"] {
            background: #fff;
            box-shadow: 0 4px 12px #0002;
            border: 1px solid #000a;
            width: 16px;
            height: 16px;
            border-radius: 8px;
        }

        &.hide-range {
            *[class*="range"] {
                background: none;
            }
        }
    }

    @media screen and (max-width: 1000px){
        .aq-slider {
            border-radius: 16px;

            &-horizontal {
                height: 32px;

                *[class*="handle"] {
                    margin-top: -16px;
                }
            }

            &-vertical {
                width: 32px;

                *[class*="handle"] {
                    margin-left: -16px;
                }
            }

            *[class*="handle"] {
                background: #fff;
                box-shadow: 0 4px 12px #0002;
                border: 1px solid #000a;
                width: 32px;
                height: 32px;
                border-radius: 16px;
            }
        }
    }
}