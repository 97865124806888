@import '../primitives/surfaces.scss';
@import '../config/color-palette.scss';
@import '../config/parameters.scss';

:root {

    /* Widgets */
    input[type=button],
    button,
    .aq-btn {
        @extend .aq-formcontrol;
        height: 32px;
        background: white;
        padding: 8px 12px;
        display: inline-flex;
        opacity: 1;
        color: $dark_base;
        font-family: Roboto, 'Helvetica', sans-serif;
        font-size: 12px;
        font-weight: normal;
        outline-offset: 0px;
        outline-color: #0000;
        cursor: default;

        * {
            color: $dark_base;
            font-weight: normal;
        }

        &.aq-btn-md {
            min-width: 100px;
        }

        &.aq-rounded {
            border-radius: 2rem;
        }

        &.aq-primary {
            background: $primary;
            color: $light_base;
            @extend .aq-formcontrol-dark;

            * {
                color: $light_base;
            }
        }

        &.aq-secondary {
            background: $secondary;
            color: $light_base;
            @extend .aq-formcontrol-dark;

            * {
                color: $light_base;
            }
        }

        &.aq-suggestive {
            background: $suggestive_action;
            color: $light_base;
            @extend .aq-formcontrol-dark;

            * {
                color: $light_base;
            }
        }

        &.aq-destructive {
            background: $destructive_action;
            color: $light_base;
            @extend .aq-formcontrol-dark;

            * {
                color: $light_base;
                margin-right:2px;
            }
        }

        &.aq-outline-success {
            color: $success;
            border: 1px solid $success !important;
            @extend .aq-formcontrol-dark;

            * {
                color: $success;
                margin-right:2px;
            }
        }

        &.aq-outline-destructive-footer {

            color: $destructive_action;
            background-color: transparent;
            @extend .aq-formcontrol-dark;

            * {
                color: $destructive_action;
            }
        }

        &.aq-success {
            background: $success;
            color: white;
            @extend .aq-formcontrol-dark;

            * {
                margin-right: 1px;
                color: white;
            }
        }

        &.aq-warning {
            background: $warning;
            color: $dark_base;

            * {
                color: $dark-base;
            }
        }

        &.aq-outline{
            background:$light_base;

            *{
                color:$dark-base
            }
        }

        &.aq-outline-primary{
            color:$info;
            border-color: $info;
            * {
                color:$info;
                margin-right:2px;
            }
        }
        .rounded{
            border-radius: 5px;
        }

        &.aq-outline-circle{
            color:$light_base;
            background:$info;
            border-radius: 50%;
            *{
                color:$light_base;
            }
        }

        &.aq-outline-table{
            color:$info;
            background:#fff;
            border-radius: 50%;
            box-shadow: 0px 4px 8px rgba(0,0,0,0.2);
            *{
                color:$info
            }
        }
    }

    input[type=button],
    button:hover,
    .aq-btn:hover {
        background: darken(white, 4%);
        border: 1px solid #0002;
        border-bottom: 1px solid #0004;

        &.aq-primary {
            background: lighten($primary, 4%);
        }

        &.aq-secondary {
            background: lighten($secondary, 4%);
        }

        &.aq-suggestive {
            background: lighten($suggestive_action, 4%);
        }

        &.aq-destructive {
            background: lighten($destructive_action, 4%);
        }

        &.aq-outline-success {
            background: lighten($success, 4%);
            color: white;
            border: 1px solid $success !important;

            * {
                color: white
            }
        }


        &.aq-success {
            background: darken($success, 8%);
            color: white;

            * {
                color: white;
                margin-right:2px;
            }
        }

        &.aq-outline-destructive-footer {
            color: lighten($destructive_action, 4%);
            background-color: transparent;

            * {
                color: lighten($destructive_action, 4%);
            }
        }

        &.aq-warning {
            background: lighten($warning, 4%);
            color: $dark_base;

            * {
                color: $dark-base;
            }
        }

        &.aq-outline{
            background:darken($light_base,8%);

            *{
                color:$dark-base
            }
        }
        &.aq-outline-primary{
            color:$info;
            border-color: $info;
            *{
                color:$info;
            }
        }

        &.aq-outline-circle{
            color: darken($light_base,8%);
            background:darken($info,8%);
            border-radius: 50%;
            *{
                color:darken($light_base,8%);
            }
        }
        &.aq-outline-table{
            color:darken($info,8%);
            background:darken($light_base,8%);
            border-radius: 50%;
            box-shadow: 0px 4px 8px rgba(0,0,0,0.4);
            *{
                color:darken($info,8%);
            }
        }
     
    }

    input[type=button],
    button:active,
    .aq-btn:active {
        background: darken(white, 8%);
        border: 1px solid #0001;

        &.aq-primary {
            background: darken($primary, 8%);
        }

        &.aq-secondary {
            background: darken($secondary, 8%);
        }

        &.aq-suggestive {
            background: darken($suggestive_action, 8%);
        }

        &.aq-destructive {
            background: darken($destructive_action, 8%);
           
        }

        &.aq-outline-success {
            background: darken($success, 8%);
            border: 1px solid $success !important;
            color: white;

            i {
                color: white
            }
        }

        &.aq-success {
            background: darken($success, 8%);
            color: white;

            * {
                color: white;
            }
        }

        &.aq-outline-destructive-footer {
            color: darken($destructive_action, 8%);
            background-color: transparent;

            * {
                color: darken($destructive_action, 8%);
            }
        }

        &.aq-warning {
            background: darken($warning, 8%);
            color: $dark_base;

            * {
                color: $dark-base;
            }
        }

        &.aq-outline-table{
            color:$info;
            background:$light_base;
            border-radius: 50%;
            *{
                color:$info
            }
        }
    }
}