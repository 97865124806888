@import '../config/color-palette.scss';
@import '../primitives/animations.scss';

:root {
    .aq-status-pill {
        display: inline-block;
        backface-visibility: hidden;
        background: rgba($primary, 0.1);
        color: $primary;
        font-size: small;
        padding: 2px;
        border-radius: 24px;
        margin-right: 4px;
        cursor: default;
        transform: translateX(0);
        overflow: hidden;
        
        animation: pop-blur-start 1s cubic-bezier(.24,1.57,.64,.99);

        &-inner {
            min-width: max-content;
        }

        &-message {
            display: inline-block;
            padding: 4px 12px;
            font-weight: 400;
        }

        &-action {
            display: inline-block;
            padding: 4px 12px;
            background: #fff;
            border-radius: 22px;
            cursor: pointer;
            font-weight: 600;

            &:hover {
                background: $primary;
                color: #fff;
            }
        }

        &.warning {
            background: rgba($warning, 0.1);
            color: $warning;

            .aq-status-pill-action:hover {
                background: $warning;
            }
        }

        &.danger {
            background: rgba($danger, 0.1);
            color: $danger;

            .aq-status-pill-action:hover {
                background: $danger;
            }
        }

        &.success {
            background: rgba($success, 0.1);
            color: $success;

            .aq-status-pill-action:hover {
                background: $success;
            }
        }

        &.secondary {
            background: rgba($secondary, 0.1);
            color: $secondary;

            .aq-status-pill-action:hover {
                background: $secondary;
            }
        }
    }
}