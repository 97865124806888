.aq-bob-up-down {
    animation: aq-bob-up-and-down 1s ease-in-out infinite;
}

@keyframes aq-bob-up-and-down {
    0% {
        margin-top: 0;
    }
    25% {
        margin-top: -8px;
    }
    75% {
        margin-top: 2px;
    }
}

.page-init {
    backface-visibility: hidden;
    animation: zoom-fade 0.5s cubic-bezier(0, 0, 0, 1) forwards;
}

@keyframes zoom-fade {
    from {
        transform: scale(0.9);
        opacity: 0;
    }
    to {
        transform: scale(1);
        opacity: 1;
    }
}

@keyframes pop-blur-start {
    from {
        filter: blur(8px);
        transform-origin: right;
        transform: translateX(20px) scaleX(0.9);
    }
    to {
        filter: blur(0);
        transform-origin: right;
        transform: translateX(0) scale(1);
    }
    0%{
        opacity: 0;
    }
    25% {
        opacity: 1;
    }
}