/* You can add global styles to this file, and also import other style files */
@import "primeicons/primeicons.css";


/* Global style configs */
.i18n-flag {
    display: inline-block;
    width: 50px;
    height: 25px;
    vertical-align: middle;
    background-size: cover;
    background-position: center;
    border-radius: 4px;
    box-shadow: 0 1px 4px #000b, inset 0 1px #fff4, inset 0 -1px #fff3, inset 0 -1px #0005;
    border: 1px solid #333;
  }

  // @import url('./styles.responsive.css');


/* Dummy */
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #333;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

/* Global style configs */
.i18n-flag {
  display: inline-block;
  width: 50px;
  height: 25px;
  vertical-align: middle;
  background-size: cover;
  background-position: center;
  border-radius: 4px;
  box-shadow: 0 1px 4px #000b, inset 0 1px #fff4, inset 0 -1px #fff3, inset 0 -1px #0005;
  border: 1px solid #333;
}

h5 {
  color: #165788;
  font-size: 1.04rem;
  font-weight: 600;
  margin-bottom: -4px;
}

.text-top {
  margin-bottom: 4px;
}

.p-dialog .p-dialog-header {
  color: #165788 !important;
  font-size: 1.08rem !important;
  font-weight: 600 !important;
}

.table-container {
  margin-top: 12px;
  margin-bottom: 12px;
  overflow: hidden;
  box-shadow: 0 2px 4px #00000036;
  border-radius: 4px;
  background: #fff;
}

.fix-min-width {
  min-width: 200px;
}

.table-loading {
  background-image: linear-gradient(#fff 40%, rgb(173, 233, 231) 90%, #fff);
  background-repeat: repeat;
  animation: table_loading 15s ease-out infinite;
}

@keyframes table_loading {
  0% {
    background-position: 0px 0px
  }
  100% {
    background-position: 10px 1080px;
  }
}

.p-datatable-thead {
  z-index: 0 !important;
}

.p-datatable .p-datatable-tbody > tr > td {
  border: none !important;
}

.p-datatable .p-datatable-thead > tr > th {
  border: none !important;
  font-weight: 400 !important;
}

.p-datatable .p-datatable-tbody > tr {
  background: none !important;
}

.p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even) {
  background: #6ba1fd33 !important;
}

.p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even).data-inactive {
  background: #3331 !important;
}
.p-datatable.p-datatable-striped .p-datatable-tbody > tr.data-inactive > .data {
  opacity: 0.6;
  filter: grayscale(0.2);
}

.p-datatable .p-datatable-tbody > tr.p-highlight {
  background: #007ad9 !important;
  color: #ffffff !important;
}

.p-datatable.p-datatable-striped .p-datatable-tbody > tr.p-highlight {
  background: #007ad9 !important;
  color: #ffffff !important;
}

.p-paginator-bottom {
  float: right;
}

.font-style {
  color: #477FA7 !important;
  font-size:13px;
}
.dialog-label {
  font-weight: 600;
}

.table-header-style {
  background-color: #03827E !important;
  color: #ffffff !important;
  text-align: center !important;
  font-size: 0.9rem !important;
  outline: 0 !important;
}

.p-datatable-scrollable .p-datatable-thead > tr > th {
  display: table-cell !important;
}

.p-datatable-scrollable .p-datatable-tbody > tr > td {
  display: table-cell !important;
}

.p-datatable-scrollable .p-datatable-tfoot {
  z-index: inherit !important;
  border: none !important;
  font-size: 0.9rem;
}

.p-datatable-scrollable .p-datatable-tfoot > * > * {
  border-left: none !important;
  border-right: none !important;
}

.p-datatable-scrollable .p-datatable-tfoot > * > * > * {
  text-align: center !important;
  width: 100%;
}

.p-paginator .p-paginator-pages .p-paginator-page {
  margin: 8px !important;
  border-radius: 50% !important;
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background: #fff !important;
  border: 1px solid #03827e !important;
  box-shadow: 0 0 8px #62afad50;
  color: #333 !important;
}

.p-datatable.p-datatable-gridlines .p-paginator-bottom {
  border-width: 0 1px 1px 1px;
  background: none;
  border: none;
  font-size: 0.8rem;
}

.icon-circle {
  background-color: #03827E !important;
  color: #ffffff !important;
  border: 1px solid #04B4AE !important;
}

.custom-icon {
  width: 16px;
  height: 16px;
  display: inline-block;
}

.custom-icon:hover {
  opacity: 0.6;
}

.custom-icon-edit {
  background-image: url(../assets/images/icons/edit.svg);
  background-repeat: no-repeat;
  background-size: contain;
}

.custom-icon-delete {
  background-image: url(../assets/images/icons/delete.svg);
  background-repeat: no-repeat;
  background-size: contain;
}

.custom-icon-activate {
  background-image: url(../assets/images/icons/activate.svg);
  background-repeat: no-repeat;
  background-size: contain;
}

.custom-icon-filter {
  background-image: url(../assets/images/icons/filter.svg);
  background-repeat: no-repeat;
  background-size: contain;
  opacity: 1 !important;
  margin-top: 2px;
}

.btn-suggested-action {
  background: #03827E !important;
  border: 1px solid #20B2AA !important;
  font-family: "Roboto", sans-serif;
  font-size: 0.8rem;
  font-weight: 500;
}
.btn-suggested-action:hover {
  background: #04B4AE !important;
  border: 1px solid #67e7e1 !important;
}
/*filter container related css*/
.top-container {
  margin-top: 14px;
}
.filter-section {
  background: #fff;
  border-radius: 0px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.25);
  border: 1px solid #00000009;
  max-height: 200px;
  transition: all 1s ease-out;
  overflow: hidden;
  margin-left: auto !important;
  position: relative;
}

.filter-section-hide {
  width: 36px !important;
  max-height: 36px;
  flex: none;
  margin-left: auto !important;
  border-radius: 18px;
  color: #fff;
  background: #03827E;
  border: 1px solid #20B2AA;
  box-shadow: 0 0px 2px rgba(0,0,0,0);
  cursor: pointer;
  position: relative;
}

.filter-container > div > button {
  height: 36px !important;
  padding: 0.8rem 0.875rem !important;
}

.filter-section-hide:hover {
  background: #0dcec7;
  border: 1px solid #67e7e1;
}

.filter-section-hide > .form-section {
  opacity: 0;
}
.form-section {
  padding: 12px;
  min-width: 580px;
}
.m-top {
  top: 30px;
}
.heading-left{
    margin-left:13px;
}

.m-bottom {
  bottom: 3px;
}
.header-button {
  margin-top: 12px;
  text-align: end;
}
/*modal popup css goes here*/
.modal-container {
  margin: 0 !important;
  padding: 4px !important;
  padding-top: 2px !important;
}

.modal-inner-content {
  padding: 16px;
  border: none;
}

  .modal-inner-content > .row {
    margin: 24px;
  }

.modal-content {
  box-shadow: 0 4px 8px #0002;
}

.modal-backdrop-common {
  background: #0002;
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);
}

.modal-backdrop.show {
  opacity: 1 !important;
}

.btn-set {
  float: right;
  margin-bottom: 22px;
  min-width: 200px;
}

.border-color {
  border: 1px solid red !important;
}

.starMark {
  color: red !important;
  font-weight: 500;
}

/* Custom Alert*/
.translucent-backdrop {
  background-color: #0004 !important;
  -webkit-backdrop-filter: blur(2px)!important;
  backdrop-filter: blur(2px) !important;
}

/* Prime NG Material theme overrides */
.btn-icon-primary {
  color: #82CCFF;
  cursor: pointer;
}
.btn-icon-primary:hover{
  color: #2897e0;
}
.btn-icon-destructive {
  color: #E64D4D;
  cursor: pointer;
}
.btn-icon-destructive:hover {
  color: #d31414;
}
/* Prime NG Material theme overrides */

/* Input Text */
.p-inputtext {
  width: -webkit-fill-available;
  font-size: 0.8rem !important;
}
.filter-section .p-inputtext {
  border: transparent !important;
  border-bottom: 1px solid #165788 !important;
  background: none !important;
  border-radius: 0 !important;
  width: -webkit-fill-available;
  outline:0 !important;
  box-shadow:none !important;
}

/* p-messages */
p-messages {
  position: fixed;
  top: 42px;
  right: 26px;
}

  /*.p-inputtext:hover {
    border: transparent !important;
    border-bottom: 1px solid #1b9cff !important;
  }*/
  
.p-calendar {
  width: 100% !important;
}

 /* SelectButton */
  .p-selectbutton.p-buttonset {
    display: flex !important;
  }
  .p-selectbutton.p-buttonset > .p-button {
    flex: 1 1 0 !important;
    border: 1px solid rgb(110, 160, 194) !important;
    background: none;
    color: rgb(54, 111, 150)
  }
  .p-selectbutton.p-buttonset > .p-button:hover {
    background: rgb(211, 239, 255) !important;
  }
  .p-selectbutton.p-buttonset > .p-button.p-highlight {
    flex: 1 1 0 !important;
    border: 1px solid rgb(0, 162, 255) !important;
  }
  .p-selectbutton.p-buttonset > .p-button.p-highlight:hover {
    flex: 1 1 0 !important;
    background: rgb(24, 170, 255) !important;
    border: 1px solid rgb(135, 211, 255) !important;
  }


.p-inputgroup > p-inputnumber > .p-inputnumber > .p-inputnumber-input {
  border-radius: 3px 0 0 3px !important;
}

.number-field-fill {
  width: 100% !important;
}

 /* Dropdown  */
 .p-dropdown {
   width: 100%;
 }
 .p-inputgroup > p-dropdown {
  display: grid;
  flex: 1 1 auto;
  width: 1%;
}

.p-inputgroup > p-dropdown > .p-dropdown {
  height: 100%;
  width: 100% !important;
  overflow: hidden;
}

.p-inputgroup p-dropdown:not(:last-child) .p-dropdown {
  border-right: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.p-inputgroup p-dropdown:not(:first-child) .p-dropdown {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.filter-section .p-dropdown {
  border-top: transparent !important;
  border-left: transparent !important;
  border-right: transparent !important;
  border-bottom: 1px solid #165788 !important;
  background: none !important;
  border-radius: 0 !important;
  width: 100%;
  margin-top: 3px;
  outline: 0 !important;
  box-shadow: none !important;
  top: inherit !important;
  left: inherit !important;
}
.filter-section .p-dropdown > .p-inputtext {
  border: transparent !important;
}
.filter-section .p-dropdown-label:hover {
  overflow: visible !important;
  background: #fff !important;
  box-shadow: 0 2px 4px rgba(0,0,0,0.5), inset 0 0 0 1px rgba(0,0,0,0.25) !important;
  border-radius: 4px !important;
  z-index: 40000 !important;
  width: fit-content !important;
  transition: all 0.4s linear;
  transition-delay: 0.4s;
  animation: reveal_dropdown 0.8s linear;
}

@keyframes reveal_dropdown {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

 /* Multiselect  */
.p-multiselect {
  border: transparent !important;
  border-bottom: 1px solid #165788 !important;
  background: none !important;
  border-radius: 0 !important;
  width: 100%;
  outline: 0 !important;
  box-shadow: none !important;
}

.p-sortable-column-icon {
  color: #fff !important;
  margin-top: -4px;
}

// .p-inputswitch-slider:before {
//   background: #fff !important;
// }

.dropdown-border-color {
  border-color: red !important;
}

.dropdown-border-color .p-inputtext {
  border-color: red !important;
}

.dropdown-border-color .p-inputtext-sm {
  border-color: red !important;
}

.dropdown-border-color > .p-dropdown {
  border-color: red !important;
}

/* Galleria */
.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-prev, .p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-next {
  color: #333 !important;
  background: #f4f4f4 !important;
  border-radius: 50%;
}
.p-galleria-thumbnail-container {
  border-radius: 8px;
  background: rgb(169, 205, 219) !important;
}
.p-galleria-item-container {
  overflow: hidden;
  background-color: #444;
  border-radius: 8px;
  margin-bottom: 12px;
}
.p-image-toolbar {
  border-radius: 80px;
  z-index: 1200;
  -webkit-backdrop-filter: blur(12px);
  backdrop-filter: blur(12px);
  background: #222a;
  box-shadow: inset 0 0 30px #222;
}

.p-radiobutton-label {
  margin-top: 10px !important;
}

.p-radiobutton-icon {
  background: #007ad9 !important;
}

p-radiobutton .p-radiobutton-box {
  background: #fff !important;
  box-shadow: 0 2px 6px #0004;
  border: transparent !important;
}

/* Custom Alert*/
.translucent-backdrop {
  background-color: #0004 !important;
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px) !important;
}

.label-bottom{
    margin-bottom:0px;
}

.p-dialog .p-dialog-header {
  border-bottom: none !important;
  background: #fff !important;
}

.p-dialog .p-dialog-footer {
  border-top: none !important;
  background: #fff !important;
}

.p-dialog-mask {
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
}
/*.p-dialog .p-dialog-content{
    line-height:0.8;
}*/

.btn-header-action {
  background-color: #fff !important;
  color: #03827E !important;
  border-color: #03827E !important;
  float: right !important;
  font-size: 0.7rem !important;
  padding: 0.2rem 0.8rem !important;
  border-radius: 4px !important;
}

.btn-header-action:hover {
  background-color: #29b6b1 !important;
  border-color: #29b6b1 !important;
  color: #fff !important;
}

.dialog-lg {
  width: 80vw !important;
  max-width: 60vw;
}

.dialog-md {
  width: 80vw !important;
  max-width: 55vw;
}

.dialog-sm {
  width: 60vw !important;
  max-width: 30vw;
}

.fr-report-container {
  text-align: -webkit-center !important;
}


@media only screen and (max-width: 840px) {
  .filter-section {
    max-height: 220px !important;
  }

  .filter-section-hide {
    width: 36px !important;
    max-height: 36px !important;
    flex: none;
    margin-left: auto !important;
    border-radius: 18px 4px 4px 18px !important;
    color: #fff;
    background: #03827E;
    border: 1px solid #20B2AA;
    box-shadow: 0 0px 2px rgba(0,0,0,0);
    cursor: pointer;
  }
}

@media only screen and (max-width: 1200px) {
  .dialog-lg {
    max-width: 80vw !important;
  }

  .dialog-md {
    max-width: 80vw !important;
  }

  .dialog-sm {
    max-width: 60vw !important;
  }

  .p-inputnumber-buttons-stacked.number-field-fill {
    width: calc(100% - 38px) !important;
  }
}

// To be modified
.p-button.p-button-success, .p-buttonset.p-button-success > .p-button, .p-splitbutton.p-button-success > .p-button {
  color: #ffffff !important;
  background: #34A835 !important;
  border: 1px solid #34A835 !important;
}

.p-button.p-button-success.p-button-outlined, .p-buttonset.p-button-success > .p-button.p-button-outlined, .p-splitbutton.p-button-success > .p-button.p-button-outlined {
  background-color: transparent !important;
  color: #34A835 !important;
  border: 1px solid !important;
}