/**
 * Theme file intended to be used for Alcoa applications
 * following Alcoa branding and based on Microsoft Fluent UI.
 *
 * Designed By Josshika M, Subhadip B and Subhadeep J
 */

// Configurables
@import './config/color-palette.scss';
@import './config/parameters.scss';

// Text
@import './primitives/text.scss';

// Widgets
@import './widgets/buttons.scss';
@import './widgets/menu.scss';
@import './widgets/dropdowns.scss';
@import './widgets/textboxes.scss';
@import './widgets/statuspills.scss';
@import './widgets/sliders.scss';
@import './widgets/table.scss';
@import './widgets/notification.scss';
@import './widgets/expandable_button.scss';
@import './widgets/switch.scss';
@import './widgets/input.scss';

// Layouts
@import './layouts/grid.scss';
@import './layouts/spacers.scss';
@import './layouts/headerbar.scss';
@import './layouts/sidebar.scss';
@import './layouts/panels.scss';
@import './layouts/dialogs.scss';
@import './layouts/welcomescreen.scss';

// Animations
@import './primitives/animations.scss';

:root {
    :focus {
        outline: rgba(black, 25%) solid 2px;
        outline-offset: 1px;
    }
}