@import './buttons.scss';

.aq-expandable-btn {
    @extend .aq-btn;
    border-radius: 0px;
    max-height: 200px;
    transition: all 1s ease-out;
    overflow: hidden;
    margin-left: auto !important;

    &.hide {
        @extend .aq-suggestive;
        width: 36px !important;
        max-height: 36px;
        flex: none;
        margin-left: auto !important;
        border-radius: 18px;
        cursor: pointer;

        & > .form-section{
            opacity: 0;
        }
    }
}