.row {
  display: flex;
  height: min-content;

  .col {
    flex: 0 0 auto;

    &-auto {
      width: auto;
      flex: auto;
    }

    &-25 {
      width: 25%;
    }

    &-20 {
      width: 20%;
    }

    &-30 {
      width: 30%;
    }

    &-33 {
      width: 33.33%;
    }

    &-40 {
      width: 40%;
    }

    &-50 {
      width: 50%;
    }

    &-60 {
      width: 60%;
    }

    &-66 {
      width: 66.66%;
    }

    &-70 {
      width: 70%;
    }

    &-75 {
      width: 75%;
    }

    &-100 {
      width: 100%;
    }
  }
}

.scrollable {
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 8px;

    &-track {
      background: #3334;
      border-radius: 20px;
    }

    &-thumb {
      background-color: #3338;
      border-radius: 20px;
      border: transparent;
      transition: background-color 0.2s ease-out;
    }

    &-thumb:hover {
      background-color: #333;
    }
  }
}