@import '../primitives/surfaces.scss';
@import '../config/parameters.scss';

:root{
  input[type=text], .aq-textbox {
    @extend .aq-formcontrol;
    background: white;
    border-radius: $border_radius;
    height: 32px;
    font-size: 12px;
    font-weight: normal;
    font-family: Roboto, 'Helvetica', sans-serif;
    box-shadow: none !important;
  }
}