.welcome-screen {
    text-align: center;
    padding-top: calc(50dvh - 136px);

    h1 {
        font-size: xx-large;
        font-weight: 200;
    }

    h2 {
        font-size: larger;
        font-weight: 400;
        opacity: 0.5;
    }
}