@import '../config/parameters.scss';
@import '../config/color-palette.scss';


:root {
    .aq-table {
        margin: 8px;
        border-radius: 8px;
        overflow: hidden;
        box-shadow: 0 8px 22px #0004;

        thead {
            tr:first-child {
                background: $suggestive_action;
                color: white;
            }

            th {
                background: none;
                color: white;
                outline: 0 !important;
            }
        }
        
        *[class*="paginator"] {
            justify-content: flex-end;

            * {
                justify-content: initial;
            }

            button {
                border-radius: 50%;
                width: 38px;
                height: 38px;
                text-align: center;
                justify-content: center;
                transform: scale(0.8);
                transition: all 0.3s cubic-bezier(0, 0, 0, 1);
            }

            button[class*="first"],
            button[class*="last"],
            button[class*="prev"],
            button[class*="next"] {
                border: none;
                transform: scale(1);
            }

            button[class*="highlight"] {
                transform: scale(1);
                border: none;
                box-shadow: 0 2px 8px rgba($suggestive_action, 0.1), inset 0 0 0 1px rgba($suggestive_action, 0.5);
                background: rgba($suggestive_action, 0.1);
                color: $suggestive_action;
            }
        }

        &-sort-icon {
            margin-left: 8px;
            color: white !important;
            filter: brightness(5);
        }
    }
}
