@import '../primitives/shadows.scss';
@import '../config/color-palette.scss';

.aq {
    &-floating-panel {
        &-group {
            margin-top: 8px;
            border-radius: 8px;
            padding: 0;
            overflow: hidden;
            box-shadow: $shadow-layer-2;
            width: min-content;
            height: max-content;

            button {
                display: block;
                background: none;
                border-radius: 0 !important;
                border: none !important;
                width: 48px;
                min-width: 48px;
                max-width: 48px;
                height: 48px;
                min-height: 48px;
                max-height: 48px;
                outline: 0 !important;
                box-shadow: none !important;
            }

            button:hover {
                background: none !important;
                border: none !important;
                border-color: #0000 !important;
            }

            button:focus{
                background: none !important;
                border: none !important;
                border-color: #0000 !important;
            }

            @media (min-width: 1000px) {
                button {
                    width: 32px;
                    max-width: 32px;
                    min-width: 32px;
                    height: 32px;
                    min-height: 32px;
                }

                button:hover {
                    background: #fff4 !important;
                    border: none !important;
                    border-color: #0000 !important;
                }
            }
        }

        &.dark {
            .aq-floating-panel-group {
                background: #333;

                button {
                    filter: brightness(5);
                }
            }
        }
    }

    &-content {
        &-header {
            padding: 2px 8px;
            font-size: 14px;
            font-weight: 600;
            color: #3c3c3c;
            background: #f8f8f8;
            border-bottom: 1px solid #b7bbc177;
            height: 48px;
            -webkit-user-select: none;
            user-select: none;

            h2 {
                font-size: 15px;
                font-weight: 700 !important;
                float: left;
                margin-left: 4px;
            }

            .toolbar {
                display: flex;
                flex-direction: row;
                justify-content: end;
                gap: 8px;
                padding: 6px 0;
                float: right;
                align-items: center;
            }
            
        }

        &-body {
            padding: 12px;
            height: calc(100dvh - 97px);
            overflow: auto;

            h2 {
                color: $primary;
                font-size: 14px;
                font-weight: 500;
                margin-top: 0px;
                margin-bottom: 12px;
            }
        }

        .aq-alert-banner {
            text-align: center;
            font-size: small;
            padding: 8px;
            border-top: 1px solid;
            border-bottom: 1px solid;
            box-shadow: inset 0 1px 0 #fff2, 0 1px 0 #0002;

            &-error {
                background: rgba($destructive_action, 0.25);
                color: $destructive_action;
                border-color: rgba($destructive_action, 0.5);
            }
        }

        animation: fade-up 0.5s cubic-bezier(0, 0, 0, 1);
    }

    @keyframes fade-up {
        from {
            transform: translateY(32px);
            opacity: 0;
            filter: blur(4px);
        }
        to {
            transform: translateY(0);
            opacity: 1;
            filter: blur(0);
        }
    }
}