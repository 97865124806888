:root {
    .m {
        &-12 {
            margin: 12px;
        }

        &t-0 {
            margin-top: 0px !important;
        }
        &b-0 {
            margin-bottom: 0px !important;
        }
        &l-0 {
            margin-left: 0px !important;
        }
        &r-0 {
            margin-right: 0px !important;
        }
    }

    .p {
        &-8 {
            padding: 8px;
        }

        &e-14 {
            padding-right: 14px;
        }
    }
}
