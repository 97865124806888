/* You can add global styles to this file, and also import other style files */
@import './aqua/theme.scss';
@import "../../node_modules/primeng/resources/themes/fluent-light/theme.css";
@import "../../node_modules/primeng/resources/primeng.css";
@import "../../node_modules/primeicons/primeicons.css";
@import "./prime_ng_override.scss";

// @import "icons.scss";

html {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    background: #1f4266;
    /* App unloaded background */
    box-shadow: inset 0 0 0 1px #1d5f92;
    /* App border */
}

body {
    width: calc(100% - 2px);
    height: calc(100% - 2px);
    margin: 1px !important;
    padding: 0;
    background: linear-gradient(#165688 64px, #165688cc), url('../assets/images/bg-image.png');
    /* App loaded background */
    background-size: cover;
    background-position: 64px center;
    background-repeat: no-repeat;
}


:root {
    body {
        font-family: 'Inter', sans-serif !important;
    }
}

.container-fluid, .container, .container-lg, .container-md, .container-sm, .container-xl{
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}