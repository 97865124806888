$primary:#165688;
$secondary:#344054;
$warning:#F79009;
$danger:#F04438;
$severe_warning:$danger;
$success:#0EB127;
$info:#1C7CED;
$dark_base:#333333;
$light_base:#f8f8f8;
$suggestive_action:#03827E;
$destructive_action:#D92D2D;
$light-blue:#F1F6FF;
