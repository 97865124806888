@import '../config/parameters.scss';
@import '../primitives/shadows.scss';

.aq-notification {
    background: linear-gradient(#f3f3f3dd, #f0f0f0cc);
    border-radius: $border_radius;
    border: 1px solid #fffe;
    box-shadow: $shadow_layer_2, $shadow_layer_1;
    -webkit-backdrop-filter: blur(8px);
    backdrop-filter: blur(8px);
    width: 256px;
    height: fit-content !important;
    overflow: visible;
    cursor: default !important;

    &-icon-area {
        width: 32px;
        max-width: 32px;
        min-width: 32px;
        height: 32px;
        background: #3332;
        border-radius: 4px;
        border: 1px solid #0004;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0 1px 4px #0004;

        > * {
            opacity: 0.75;
        }
    }

    &-close-btn {
        position: absolute;
        border-radius: 20px !important;
        max-width: 28px !important;
        width: 28px !important;
        max-height: 28px !important;
        border: 1px solid white !important;
        box-shadow: 0 4px 8px #0002, 0 0 0 1px #0002 !important;
        color: #f3f3f3;
        background-color: #333 !important;
        top: -12px;
        left: -12px;
        opacity: 0 !important;
        transition: all 0.2s ease-out;

        & > *{
            filter: brightness(4);
            transform: translate(-7px, -3px)
        }

        &:hover {
            background-color: #a33 !important;
            transition: all 0.2s ease-out;
        }
    }

    &-content {
        font-size: 12px;
        margin-left: 6px;

        &-title {
            font-weight: 800;
            color: #333;
        }

        &-detail {
            color: #666;
            font-weight: 500;
        }
    }

    &:hover {
        .aq-notification-close-btn {
            opacity: 1 !important;
        }
    }
}
